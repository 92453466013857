import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { UnityGame } from './UnityGame'
import { useGetPlayableGame } from './useGetPlayableGame'
import { GameBuild } from './types'
import { AES, enc } from 'crypto-js'

export const PlayPage = () => {
  const container = useRef<HTMLDivElement>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const [isFullScreen, setIsFullScreen] = useState(
    searchParams.get('fullscreen') === 'true'
  )

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  )

  const [showAlert, setShowAlert] = useState(!isLandscape)

  const checkOrientation = () => {
    const landscape = window.innerWidth > window.innerHeight
    setIsLandscape(landscape)
    if (landscape) setShowAlert(false)
  }

  const [actualToken, setActualToken] = useState(
    localStorage.getItem('odyn_jwt')
  )
  const encryptedToken = searchParams.get('t')

  const { activeGame } = useGetPlayableGame()

  useEffect(() => {
    window.addEventListener('resize', checkOrientation)
    return () => {
      window.removeEventListener('resize', checkOrientation)
    }
  }, [])

  useEffect(() => {
    if (encryptedToken) {
      console.log(encryptedToken)

      const bytes = AES.decrypt(
        decodeURIComponent(encryptedToken),
        import.meta.env.VITE_ODYN_TOKEN_KEY
      )
      const token = bytes.toString(enc.Utf8)
      localStorage.setItem('odyn_jwt', token)
      setActualToken(token)

      // Remove token from  url
      searchParams.delete('t')
      setSearchParams(searchParams)
    }
  }, [encryptedToken])

  return (
    <div ref={container} className="w-screen h-screen bg-black">
      {!isLandscape && showAlert && (
        <div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          style={{ animation: 'rotateAlert 1.5s infinite' }}
        >
          <div className="text-center bg-white rounded-lg shadow-lg p-6 w-80">
            <p style={{ color: 'black', fontSize: '18px' }}>
              Please rotate your device to landscape mode, or expand your
              browser window.
            </p>
            <button
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowAlert(false)}
            >
              Dismiss
            </button>
          </div>
        </div>
      )}

      <UnityGame gameBuild={activeGame as GameBuild} jwt={actualToken || ''} />
    </div>
  )
}
